<template>
    <div class="wrap">
        <div class="header"></div>
        <section class="padding40">
            <product class="part1" @btnClick="handelClick">
                <template v-slot:headerTitle>
                    <span>基金智能投研平台</span>
                </template>
                <template v-slot:headerContent>
                    <span>
                        基金投研平台以提升投资绩效为唯一目标的，提供基金数据和投研成果的查询与展示、投研实时计算与分析工具、基金投资组合管理、投研流程管理、以及投后绩效与风险管理系统。为投资机构的研究员、投资经理提供以数据挖掘、量化建模、机器学习为特色的投研管理系统，为投资中台、销售支持、基金投顾提供市场权威的投研成果和基金组合投后分析报告。
                    </span>
                </template>
                <product-item v-for="(item, index) in part1Data" :key="index">
                    <template v-slot:title>
                        <span>{{ item.title }}</span>
                    </template>
                    <template v-slot:content>
                        <span>{{ item.content }}</span>
                    </template>
                </product-item>
            </product>
            <img src="../../assets/product/img1.png" alt="" class="img1">
        </section>

        <section class="bg-gray padding40">
            <img class="img2" src="../../assets/product/img2.png" alt="">
            <product class="part2" @btnClick="handelClick">
                <template v-slot:headerTitle>
                    <span>数智化投顾平台</span>
                </template>
                <template v-slot:headerContent>
                    <span>
                        为财富管理机构提供面向大众客户的线上的基金投研和投后服务，辅助人工投顾服务高净值客户，赋能投顾的线上与线下服务的工具和内容。产品形态包括Ipad APP、H5、企业微信小程序，产品功能包括基金投研、投资者账户诊断、财富规划、资产配置、报告编制。产品内容包括基金AI资讯、AI研报、AI投资者教育。
                    </span>
                </template>
                <product-item v-for="(item, index) in part2Data" :key="index">
                    <template v-slot:title>
                        <span>{{ item.title }}</span>
                    </template>
                    <template v-slot:content>
                        <span>{{ item.content }}</span>
                    </template>
                </product-item>
            </product>
        </section>

        <!-- <section class="padding40">
            <product class="part3" @btnClick="handelClick">
                <template v-slot:headerTitle>
                    <span>智策移动端</span>
                </template>
                <template v-slot:headerContent>
                    <span>
                        智策移动端旨在帮助投顾更好的服务客户，投顾在智策PC端或移动端的内容可以轻松地分享给客户，与客户的沟通将更加轻松。
                    </span>
                </template>
                <product-item v-for="(item, index) in part3Data" :key="index">
                    <template v-slot:title>
                        <span>{{ item.title }}</span>
                    </template>
                    <template v-slot:content>
                        <span>{{ item.content }}</span>
                    </template>
                </product-item>
            </product>
            <img class="img3" src="../../assets/product/img3.png" alt="">
        </section> -->

        <ApplyForm id="jump"/>

        <!-- <el-dialog
        title="预约演示"
        top="30vh"
        width="400px"
        :visible="dialogVisible"
        @close="close">
            <div class="flex-row">
                <div style="font-size:52px"><svg-icon icon-class="phone"></svg-icon></div>
                <div style="margin-left:8px;"><p style="font-size: 14px">请拨打以下电话，我们会第一时间安排演示</p><p style="font-size: 18px">吴经理：136 1185 8287</p></div> 
            </div>
        </el-dialog> -->
    </div>
</template>

<script>
import Product from './componets/Product'
import ProductItem from './componets/Productitem'
import ApplyForm from '../../components/ApplyForm'

export default {
  components: { Product, ProductItem, ApplyForm },
  data() {
      return {
          part1Data: [
              {title: '基金筛选', content: "基金分类、指标筛选、因子打分、产品比较、计算分析、预测性评价，逐层构建基金池；提供入池、审批、维护、合规风控的流程管理。"},
              {title: '基金评价', content: "定量研究包含绩效指标、净值回归、估值表分析、交易特征、风格漂移、绩效归因、风险预测；以及在线尽调管理，包括尽调模板、报告编写和留档保存。"},
              {title: '组合管理', content: "战术资产配置和基金组合的收益-风险优化与风险预算优化，组合策略的收益回测、归因分析、持仓配置穿透，实盘模拟跟踪，以及投后的组合绩效归因和风险监控。"},
              {title: '报告系统', content: "服务内部投研和对外投资者交流，提供定制化和自定义编辑的分析报告，包括对基金市场、基金池、单基金、组合模拟策略、实盘基金组合的绩效和持仓分析报告，支持估值表和母基金交易数据导入。"},
          ],
          part2Data: [
              {title: '投顾展业终端', content: "以网页、企微小程序、IPad App 形态提供给线下投顾用于对客服务的工具和内容，包括产品介绍、客户拜访、客户账户诊断、投资规划、资产配置建议书等。"},
              {title: '线上理财商城', content: "为金融机构App上的个人投资者提供基金诊断、股票选基、基金排行、基金筛选、基金比较、经理画像等各类工具。"},
              {title: '基金投资策略', content: "基于AI算法提供ETF基金短期趋势信号等基金投资策略类投顾产品。"},
              {title: 'AI基金资讯', content: "基于基金知识库和AI技术，提供基金资讯与专业解读相结合资讯内容和基金产品报告等。"},
          ],
        //   part3Data: [
        //       {title: '早报', content: "提供基金市场的早报功能，一键分发给客户，为客户提供最及时最丰富的资讯内容。"},
        //       {title: '基金画像', content: "在智策PC版基金评测的基础上，浓缩成精简的基金画像，结果更加直观同时支持快速分享给客户。"},
        //       {title: '资产配置', content: "帮助投顾通过自定义风险等级、自定义目标基金池来为客户设计资产配置方案。通过智君自主研究的基金/经理评价模型、资产配置模型为客户提供最优的配置方案，同时支持分享给客户。"},
        //       {title: '客户管理', content: "与PC版互通，可以查看客户的持仓情况，通过持仓分析可以了解到客户持仓分布以及持仓表现，为客户提供进一步的投后服务。"},
        //     //   {title: '知识卡片', content: "支持针对公募/私募基金产品、公募基金经理、基金公司的筛选，提供超百个指标的筛选条件。"},
        //   ],
          dialogVisible: false
      }
  },
  methods:{
      handelClick() {
        //   this.dialogVisible = true
        window.location.hash = "#jump"
      },
    //   close() {
    //       this.dialogVisible = false
    //   }
  }

}
</script>

<style lang="less" scoped>
.header{
    width:100%;
    height: 320px;
    background: url('../../assets/banner/product.png') no-repeat center;
    background-size: cover;
}
section{
    display: flex;
    justify-content: center;
    margin-top: 83px;
}
.padding40{
    margin-top: 43px;
    padding: 40px 0;
}
.img1{
    width: 522px;
    height: 551px;
    margin: 10px 40px;
    /* background-color: aqua; */
}
.img2{
    width: 522px;
    height: 554px;
    margin: 10px 40px;
    /* background-color: aqua; */
}
.img3{
    width: 454px;
    height: 484px;
    margin: 10px 40px;
    /* background-color: aqua; */
}
.bg-gray{
    background: #fafafa;
}
.flex-row{
    display:flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    color: #666;
    line-height: 28px;
    padding: 40px 16px 48px;

}
/deep/ .el-dialog__header{
    background:#4E7CEE;
    padding: 23px;
}
/deep/ .el-dialog__title{
    color: #fff;
    font-size: 18px;
    font-weight: 400;
}
/deep/ .el-dialog__close.el-icon.el-icon-close{
    display: none;
}

</style>